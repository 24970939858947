<template>
  <transition name="fade">
    <div v-if="value" class="modal-overlay" @click.prevent.stop="onClickOverlay">
      <div class="modal-content">
        <slot></slot>

        <div class="modal-footer">
          <a type="button" @click.prevent.stop="$emit('close')" class="modal-close">확인</a>
          <slot name="custom-action" class="custom-action"></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'ModalComponent',
  model: {
    prop: 'value',
    event: 'input'
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    permanent: {
      type: Boolean,
      default: false,
    }
  },
  methods: {
    onClickOverlay() {
      if (this.permanent) {
        return;
      }

      this.close();
    },
    close() {
      this.$emit('input', false);
    }
  }
};
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */
{
  opacity: 0;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.5s;
  z-index: 999999;
}

.modal-content {
  background: white;
  padding: 2rem;
  border-radius: .5rem;
  max-width: 90%;
  width: 32rem;
  height: auto;
  max-height: 75%;

  font-family: 'esamanru', sans-serif !important;
  font-size: 1.2rem;
}

.modal-close {
  color: #444;
  margin-right: 1rem;
}

.modal-content b {
  color: #d2214c;
}

.modal-content p {
  line-height: 1.35;
}

.modal-footer {
  padding-top: 1rem;
  display: flex;
  justify-content: right;
  flex-wrap: nowrap;
  text-align: right;
}

.modal-footer > * {
  text-decoration: none;
  color: inherit;
}

.modal-footer > *:hover {
  font-weight: bold;
}
</style>
